$bg: #000; // my background color

@function text-clr($color) {
  @if (lightness($color) > 60) {
    @return #000;
  } @else {
    @return #FFF;
  }
}
.btncontenedor {
    $alto:50px;
  width: calc(100% - 40px);
  position: relative;
  height: $alto;
  margin-top: 20px;
  &:first-child{
    margin-top: 0px;
}
  .Boton {
      $color:$color3;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: $alto;
    border-radius: 5px;
    border: none;
    box-shadow: $sombra1;
    background-color: $color;
    color: text-clr($color);
    font-weight: bold;
    text-transform: uppercase;
    &:focus{
        outline: none;
    }
    &:active{
        background-color: darken($color: $color, $amount: 10%);
        box-shadow: none;
    }
  }
  .check {
    $tamano: 30px;
    position: absolute;
    bottom: -#{$tamano/2};
    right: -#{$tamano/2};
    width: $tamano;
    height: $tamano;
    background-color: #8e0000;
    border-radius: 50%;
    display: none;
    justify-content: center;
    align-items: center;
    &.activo {
      display: flex;
      box-sizing: border-box;
      padding: 5px;
    }
  }
}
@mixin CajaBase {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}
.CajaFechas {
  .CajaFechas1 {
    @include CajaBase();
  }
  .CajaFechas2{
    @include CajaBase();
    label{
        margin-top: 10px;
        margin-bottom: 5px;
        font-weight: bold;
        text-transform: uppercase;
    }
    input{
        width: calc(100% - 40px);
        height: 40px;
        font-size: 20px;
        &:first-child{
            margin-bottom: 15px;
        }
    }
  }
}
.FiltroModalidades{
    @include CajaBase();
}
.Setting{
    @include CajaBase();
}