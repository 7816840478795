$color1:#A6C231; 
$color2:#c43e2c;
$color3:#A6C231;
$sombra1: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
@mixin degradado1 {
    background: #014461;
    background: radial-gradient(circle, #014461 0%, #013045 100%);
}
@mixin todapantalla {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
}
@mixin centrarimagen {
    background-position: center;
    background-repeat: no-repeat;
}
$tablet : '(min-width: 768px)';
$escritio : '(min-width: 992px)';