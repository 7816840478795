@import "./variables.scss";
$TabletPortrait:"screen and (orientation: portrait) and  (min-width:700px)";
$TabletLandscape:"screen and (orientation: landscape ) and  (min-width:900px)";
$DesktopLandscape:"screen and (orientation: landscape ) and  (min-width:1200px) and (min-height:650px)";
.Drawer {
    position: absolute;
    bottom: $altoCabezayPies;
    left: 0px;
    right: 0px;
    height: 0px;
    z-index: 300;
    white-space: nowrap;
    overflow: hidden;
    transition: 250ms;
    @media #{$TabletPortrait}{
        height: calc(100% - (#{$altoCabezayPies}));
        right: auto;
        width: 0px;
    }
    @media #{$TabletLandscape}{
        height: calc(100% - (#{$altoCabezayPies}));
        right: auto;
        width: 0px;
    }
    @media #{$DesktopLandscape}{
        top: 60px;
        width: 300px;
    }
    &.activo{
        height: calc(100% - (#{$altoCabezayPies}));
        white-space: normal;
        overflow: visible;
        @media #{$TabletPortrait}{
            width: 100%;
        }
        @media #{$TabletLandscape}{
            width: 100%;
        }
        @media #{$DesktopLandscape}{
            width: 300px;
        }
    }
    .Velo{
        background-color: #0000007e;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        top: 0px;
    }
    .DrawerBox {
        background-color: white;
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        height: calc(100% - 35px);
        z-index: 350;
        border-top-left-radius: 3%;
        border-top-right-radius: 3%;
        @media #{$TabletPortrait}{
            right: auto;
            width: 300px;
            height: 100%;
            border-top-left-radius: 0px;
            border-bottom-right-radius: 3%;
            box-sizing: border-box;
            padding-top: 10px;
        }
        @media #{$TabletLandscape}{
            right: auto;
            width: 300px;
            height: 100%;
            border-top-left-radius: 0px;
            border-bottom-right-radius: 3%;
            box-sizing: border-box;
            padding-top: 10px;
        }
        @media #{$DesktopLandscape}{
            border-radius: 0px;
        }
        .DrawerBoxArrow{
            height: 40px;
            display: flex;
            justify-content: center;
            @media #{$TabletPortrait}{
                display: none;
            }
            @media #{$TabletLandscape}{
                display: none;
            }
        }
        .DrawerBoxContenido{
            height: calc(100% - 40px);
            overflow-y: auto;
            .EstudiosBox{
                display: flex;
                align-items: center;
                flex-direction: column;
                .Item{
                    $margen:5px;
                    background-color: $color3;
                    width: 250px;
                    height: 60px;
                    border-radius: 10px;
                    margin-top: $margen;
                    margin-bottom: $margen;
                    color: white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-sizing: border-box;
                    box-shadow: $sombra1;
                    text-transform: uppercase;
                    &:active{
                        background-color: #005757;
                    }
                    &.activo{
                        background-color: #c63d2d;
                    }
                }
                .InformeItem{
                    $basecolor:$color2;
                    $margen:5px;
                    //background-color: $basecolor;
                    border: 2px solid $basecolor;
                    width: 250px;
                    height: 60px;
                    border-radius: 10px;
                    margin-top: $margen;
                    margin-bottom: $margen;
                    color: $basecolor;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    box-sizing: border-box;
                    box-shadow: $sombra1;
                    text-transform: uppercase;
                    &:active{
                        background-color: darken($basecolor, $amount: 10%);
                        color: white;
                    }
                }
            }
        }
    }
}